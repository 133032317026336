'use client';

import React, { useContext, useEffect, useState } from 'react';

import { useUserLocalization } from '@/hooks/useUserLocalization';
import { MicroLocationDto } from '@/lib/locations/dto/micro-location.dto';
import {
  fetchIndustriousMicroLocations,
  fetchNearestLocation,
} from '@/lib/locations/location-api';
import { DEFAULT_LOCALE } from '@/utils/constants';

type IndustriousMicroLocationsContextType = {
  locations: MicroLocationDto[];
  nearestLocation?: MicroLocationDto;
};

const IndustriousMicroLocationsContext =
  React.createContext<IndustriousMicroLocationsContextType>({
    locations: [],
  });

const IndustriousMicroLocationsProvider = ({
  locale,
  children,
}: {
  locale?: string;
  children: React.ReactNode;
}) => {
  const { lnglat } = useUserLocalization();
  const [locations, setLocations] = useState<MicroLocationDto[]>([]);
  const [nearestLocation, setNearestLocation] = useState<MicroLocationDto>();

  const localeToUse = locale ?? DEFAULT_LOCALE;

  useEffect(() => {
    if (lnglat == null) return;

    const fetchNearestLoc = async () => {
      const nearestLocation = await fetchNearestLocation({
        locale: localeToUse,
        latitude: lnglat.lat,
        longitude: lnglat.lng,
      });
      setNearestLocation(nearestLocation);
    };

    fetchNearestLoc();
  }, [localeToUse, lnglat]);

  useEffect(() => {
    const fetchLocations = async () => {
      const locs: MicroLocationDto[] = await fetchIndustriousMicroLocations(
        localeToUse
      );
      setLocations(locs);
    };

    fetchLocations();
  }, [localeToUse]);

  return (
    <IndustriousMicroLocationsContext.Provider
      value={{ locations, nearestLocation }}
    >
      {children}
    </IndustriousMicroLocationsContext.Provider>
  );
};

const useIndustriousMicroLocations =
  (): IndustriousMicroLocationsContextType => {
    return useContext(IndustriousMicroLocationsContext);
  };

export { useIndustriousMicroLocations };
export default IndustriousMicroLocationsProvider;
